<template>
  <div class="result-left-footer">
    <div class="compony">
      <div class="name">{{ obj.name }}</div>
      <div class="date">
        <span>考评月份:</span><span>{{ obj.date | formateDate }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'result-left-footer',
  props: {
    obj: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.result-left-footer {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 1px 0px 0px #eeeeee;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  margin-left: 15px;
  .compony {
    flex: 1;
    .name {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $lightblack;
      line-height: 20px;
      margin-bottom: 15px;
    }
    .date {
      font-size: 14px;

      font-weight: 400;
      color: $grey;
      line-height: 14px;
    }
  }
}
</style>
