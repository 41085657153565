<template>
  <div class="personal-result-view">
    <div class="result-main" v-loading="loading">
      <result-footer :obj="{ name: name, date: date }" />
      <probation-item-card :lists="resultList" :type="type" />
    </div>
    <div class="result-bottom" @click="handleEvalOrgSave(1)">
      <el-button type="primary" class="blue">提交考评</el-button>
    </div>
    <submit-list pageType="depart" :code="code" :showDialog="showDialog" :userList="userList" />
  </div>
</template>

<script>
import submitList from '@/components/organizational/organization-submit-list'
import resultFooter from '@/components/organizational/result-left-footer'
import probationItemCard from '@/components/organizational/probation-item-card.vue'
import {
  getEvalStaffSpecial,
  postEvalStaffSaveSpecial,
  postEvalStaffSavPartakSpecial,
  getEvalStafPartakeSpecial
} from '@/api/api_organization'
export default {
  name: 'probation-result',
  components: { resultFooter, probationItemCard, submitList },
  props: {
    date: {
      type: [Number, String],
      default: null
    },
    orgId: {
      type: [Number, String],
      default: null
    },
    id: {
      type: [Number, String],
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      loading: false,
      code: 99,
      userList: [],
      isSort: true,
      showDialog: false,
      resultList: []
    }
  },
  methods: {
    handleFlag(flag) {
      //是否排序过
      this.isSort = flag
    },
    handleEvalOrgSave(code = 1) {
      let params = {
        date: this.date,
        orgName: this.name,
        id: this.id,
        partakeStaffs: this.resultList,
        state: code //1 正常提交 99完成确认结果
      }
      this.handleApi(this.type, 'save', params)
        .then(res => {
          if (res.code === '000000') {
            if (code === 1) {
              //第一次提交
              this.handleList()
              this.code = 99
            } else if (code === 99) {
              //第二次提交 确认提交
              this.$msgSuccess('个人绩效考评成功')
              this.showDialog = false
              this.userList = []
              this.$router.push({ name: 'appraisal' })
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleList() {
      this.showDialog = true
      this.resultList.forEach(el => {
        let { uName, avatar, pfLevel, orgName } = el
        let user = {
          evalName: uName,
          evalAvatar: avatar,
          partakePfLevel: pfLevel,
          orgName
        }
        this.userList.push(user)
      })
    },
    /**
     * getEvalOrgTo
     */
    getList() {
      let params = {
        date: this.date
      }
      this.loading = true
      this.handleApi(this.type, 'list', params)
        .then(res => {
          this.resultList = res.data
          this.resultList.forEach(el => {
            this.$set(el, 'pfLevel', 'B')
          })
        })
        .finally(() => (this.loading = false))
    },
    async handleApi(type, fun, params = {}) {
      //3 离职员工  4 参评人
      if (type === 3) {
        if (fun === 'list') {
          return await getEvalStaffSpecial({ ...params, pocId: this.id })
        } else {
          return await postEvalStaffSaveSpecial(params)
        }
      } else {
        if (fun === 'list') {
          return await getEvalStafPartakeSpecial({ ...params, eocId: this.orgId })
        } else {
          return await postEvalStaffSavPartakSpecial(params)
        }
      }
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/organize.scss';
</style>
