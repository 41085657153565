<template>
  <div class="result-infinite">
    <div class="result-box" v-for="(list, index) in lists" :key="index">
      <div class="probation-item">
        <div @click="$router.push({ name: 'homepage-navigation', query: { userId: list.uId } })">
          <user-avatar :obj="{ avatar: list.avatar, name: list.uName }" />
        </div>
        <div class="box-left">
          <div class="title">
            <span
              class="name"
              @click="$router.push({ name: 'homepage-navigation', query: { userId: list.uId } })"
              >{{ list.uName }}</span
            >
            <span class="status">{{ list.jobState | formateVal(list.pocRatio, type) }}</span>
          </div>
          <div class="content">{{ list.jobName }}</div>
          <div
            class="grade"
            @click="handleUser(list)"
            v-if="typeof list.staffTotalScore === 'number'"
          >
            绩效总分<span class="bold">{{ list.staffTotalScore }}分</span>
            <i class="el-icon-arrow-down"></i>
          </div>
          <div v-else class="dotted">--</div>
        </div>
        <div class="box-right">
          <div class="status-box">
            <!-- <div class="level">B</div> -->
            <div class="add">
              <el-select v-model="list.pfLevel" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.code"
                  :label="item.val"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </div>
            <div class="tip">目标考评</div>
          </div>
        </div>
      </div>
      <detail :showDialog="showDetailDialog" :userObj="userObj" :pageType="pageType" :flag="1" />
    </div>
  </div>
</template>

<script>
import detail from '@/components/organizational/detail'
import userAvatar from '@/components/initiate/user-avatar'
export default {
  name: 'probation-item-card',
  components: {
    detail,
    userAvatar
  },
  computed: {
    avatar: {
      get() {
        return require('../../assets/images/profile.png')
      }
    }
  },
  props: {
    lists: {
      type: Array,
      default: () => {}
    },
    type: {
      type: [Number, String],
      default: 3
    }
  },
  data() {
    return {
      pageType: '',
      num: 0,
      userObj: {},
      showDetailDialog: false,
      options: [
        {
          code: 'S',
          val: 'S'
        },
        {
          code: 'A',
          val: 'A'
        },
        {
          code: 'B',
          val: 'B'
        },
        {
          code: 'C',
          val: 'C'
        }
      ]
    }
  },
  filters: {
    formateVal(val, pocRatio, type) {
      if (Number(type) === 4 && pocRatio === 0) {
        return '不占强制分布比例'
      }
      // 员工类型：1-试用期；2-刚转正；3-转正 4-离职
      let obj = {
        1: '试用期',
        2: '刚转正',
        3: '',
        4: '已离职'
      }
      return obj[val]
    }
  },
  methods: {
    errorHandler() {
      return true
    },
    handleUser(item) {
      this.pageType = !item.pocRatio && this.type == 4 ? 'team' : 'personal'
      this.showDetailDialog = true
      let {
        target,
        evaluate,
        orgpf,
        rpmt,
        satisfaction,
        deduction,
        innovate,
        contribute,
        extraScore,
        uName,
        customize1,
        customize2,
        customize1Name,
        customize2Name
      } = item
      this.userObj = {
        target,
        evaluate,
        orgTotalScore: orgpf,
        rpmt,
        satisfaction,
        deduction,
        innovate,
        contribute,
        partakeExtraScore: extraScore,
        evalName: uName,
        customize1,
        customize2,
        customize1Name,
        customize2Name
      }
    },
    handleChange(value) {
      console.log(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.result-infinite {
  flex: 1;
  overflow-y: scroll;
  .result-box {
    margin-left: 15px;
    padding: 20px 0px 20px 0px;
    box-shadow: 0px 1px 0px 0px #eeeeee;
    display: flex;
  }
}
.probation-item {
  display: flex;
  flex: 1;
  .box-left {
    margin-left: 10px;
    .title {
      font-size: 16px;
      font-weight: 400;
      color: $lightblack;
      line-height: 16px;
      margin-bottom: 7px;
      .status {
        margin-left: 20px;
        font-size: 14px;
        font-weight: 400;
        color: $red;
        line-height: 14px;
      }
      .name {
        cursor: pointer;
      }
    }
    .content {
      font-size: 12px;
      font-weight: 400;
      color: $darkgrey;
      line-height: 12px;
      margin-bottom: 18px;
    }
    .bold {
      font-weight: 500;
      color: $lightblack;
      margin-right: 5px;
    }
    .dotted {
      color: $grey;
    }
    .grade {
      font-size: 14px;
      cursor: pointer;
      font-weight: 400;
      color: $darkgrey;
      line-height: 14px;
    }
  }
  .box-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
    .status-box {
      .level {
        width: 95px;
      }
      .tip {
        font-size: 12px;
        text-align: center;
        font-weight: 400;
        color: $grey;
        line-height: 12px;
        margin-top: 10px;
      }

      /deep/.el-input__inner {
        width: 95px;
        box-sizing: border-box;
        height: 26px;
      }
      /deep/.el-input__inner {
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        color: $lightblack;
        line-height: 16px;
        border-radius: 0px;
        box-sizing: border-box;
        border: 1px solid #aaaaaa;
      }
      /deep/.el-input__suffix {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
